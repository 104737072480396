.system-data {
  color: #809E6A;
}

.system-data-container {
  position: fixed;
  bottom: 0;
  font-size: 10px;
  padding: 16px 0 16px 16px;
}

#system-data li {
  font-weight: bold;
}

/* MOBILE VERSION MOD */
.content {
  padding-left: 194px;
}

@media screen and (max-width: 767px) {
  .content {
    padding-left: 0;
  }
}